var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-box",
    { attrs: { bcolor: "#FFF" } },
    [
      _c(
        "c-box",
        {
          staticStyle: { "border-bottom": "1px solid #E8E8E8" },
          attrs: { padding: "10px 20px" }
        },
        [_vm._v("待办")]
      ),
      _c(
        "c-box",
        { attrs: { padding: "15px", flex: "row" } },
        [
          _c(
            "c-box",
            { staticStyle: { flex: "1" }, attrs: { flex: "row" } },
            _vm._l(_vm.options, function(item, idx) {
              return _c(
                "c-box",
                {
                  key: idx,
                  staticStyle: { flex: "1", position: "relative" },
                  attrs: {
                    height: "100px",
                    align: "center",
                    flex: "column",
                    justify: "space-between"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openPage(item)
                    }
                  }
                },
                [
                  _c("c-box", { attrs: { talign: "center" } }, [
                    _c("img", {
                      staticStyle: { width: "40px" },
                      attrs: { src: require("@/assets/imgs/todo.png") }
                    })
                  ]),
                  _c(
                    "c-box",
                    { staticClass: "fz-16", attrs: { talign: "center" } },
                    [_vm._v(_vm._s(item.label))]
                  ),
                  _c(
                    "c-box",
                    {
                      attrs: {
                        color: "#2088FB",
                        size: "16px",
                        talign: "center"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info[item.prop] || "0"))]
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "c-box",
            {
              staticStyle: { flex: "1", "border-left": "1px solid #000" },
              attrs: { flex: "row" }
            },
            _vm._l(_vm.options2, function(item, idx) {
              return _c(
                "c-box",
                {
                  key: idx,
                  staticStyle: { position: "relative" },
                  attrs: {
                    height: "100px",
                    align: "center",
                    flex: "column",
                    justify: "space-between",
                    margin: "0 0 0 50px"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openPage(item)
                    }
                  }
                },
                [
                  _c("c-box", { attrs: { talign: "center" } }, [
                    _c("img", {
                      staticStyle: { width: "40px" },
                      attrs: { src: require("@/assets/imgs/todo.png") }
                    })
                  ]),
                  _c(
                    "c-box",
                    { staticClass: "fz-16", attrs: { talign: "center" } },
                    [_vm._v(_vm._s(item.label))]
                  ),
                  _c(
                    "c-box",
                    {
                      attrs: {
                        color: "#2088FB",
                        size: "16px",
                        talign: "center"
                      }
                    },
                    [_vm._v(_vm._s(_vm.info[item.prop] || "0"))]
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }