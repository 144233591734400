var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-box",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        { attrs: { bcolor: "#EEEEEE" } },
        [
          _c("Top"),
          _c("div", { staticClass: "mg10-t" }, [_c("Todo")], 1),
          _c(
            "el-row",
            { staticClass: "mg10-t", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [_c("cerealOrderCharts")],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12, offset: 0 } },
                [_c("riceOrderCharts")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }