//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Top from './top.vue';
import Todo from './todo.vue';
import orderCharts from './orderCharts.vue';
import cerealOrderCharts from './cerealOrderCharts.vue';
import riceOrderCharts from './riceOrderCharts.vue';
import deviceOrderCharts from './deviceOrderCharts.vue';
import registerCharts from './registerCharts.vue';

export default {
  name: 'dashboard',
  components: {
    Top: Top,
    Todo: Todo,
    orderCharts: orderCharts,
    cerealOrderCharts: cerealOrderCharts,
    riceOrderCharts: riceOrderCharts,
    deviceOrderCharts: deviceOrderCharts,
    registerCharts: registerCharts },

  props: {},
  data: function data() {
    return {
      loading: false };

  },
  watch: {},
  methods: {},
  created: function created() {},
  mounted: function mounted() {} };